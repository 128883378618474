import React from "react";

const AnalyticsView = React.lazy(() => import("./views/analytics/AnalyticsView"));
const Bankroll = React.lazy(() => import("./components/bankroll"));
const EventManage = React.lazy(() => import("./views/events/EventManage"));
const EventCreate = React.lazy(() => import("./views/events/EventCreate"));
const EventView = React.lazy(() => import("./views/events/EventView"));
const GroupCreate = React.lazy(() => import("./views/groups/GroupCreate"));
const GroupSettings = React.lazy(() => import("./views/groups/GroupSettings"));
const PoolCreate = React.lazy(() => import("./views/pools/PoolCreate"));
const PoolManage = React.lazy(() => import("./views/pools/PoolManage"));
const ContestCreatePage = React.lazy(() => import("views/contest/create/ContestCreatePage"));
const ContestManagePage = React.lazy(() => import("views/contest/manage/ContestManagePage"));
const ContestViewPage = React.lazy(() => import("views/contest/view/ContestViewPage"));
const ParentPoolManage = React.lazy(() => import("./views/pools/parent/ParentPoolManage"));
const ParentPoolCreate = React.lazy(() => import("./views/pools/parent/ParentPoolCreate"));
const ParentPoolView = React.lazy(() => import("./views/pools/parent/ParentPoolView"));
const PoolView = React.lazy(() => import("./views/pools/PoolView"));
const UsersPage = React.lazy(() => import("views/users/UsersPage"));
const UserPage = React.lazy(() => import("views/users/UserPage"));
const Help = React.lazy(() => import("./views/help"));

/* prettier-ignore */
const routes = [
  { path: "/", name: "Pools", component: PoolManage, exact: true },
  { path: "/analytics", name: "Analytics", component: AnalyticsView, exact: true },
  { path: "/bankroll", name: "Bankroll", component: Bankroll, exact: true },
  { path: "/events", name: "Events", component: EventManage, exact: true },
  { path: "/events/new", name: "New Event", component: EventCreate, exact: true },
  { path: "/events/:id", name: "View", component: EventView },
  { path: "/group-settings", name: "Groups Settings", component: GroupSettings, exact: true },
  { path: "/groups/:groupId/users", name: "Users", component: UsersPage, exact: true },
  { path: "/parent-pools", name: "Manage Pools", component: ParentPoolManage, exact: true },
  { path: "/parent-pools/new", name: "Create Parent Pool", component: ParentPoolCreate, exact: true },
  { path: "/parent-pools/:id", name: "View", component: ParentPoolView, exact: true },
  { path: "/pools", name: "Pools", component: PoolManage, exact: true },
  { path: "/pools/new", name: "New Pool", component: PoolCreate, exact: true },
  { path: "/pools/:id", name: "View", component: PoolView },
  { path: "/contests", name: "Contests", component: ContestManagePage, exact: true },
  { path: "/contests/new", name: "New Contest", component: ContestCreatePage, exact: true },
  { path: "/contests/:id", name: "Contest View", component: ContestViewPage, exact: true },
  { path: "/users", name: "Users", component: UsersPage, exact: true },
  { path: "/users/:id", name: "User Page", component: UserPage, exact: true },
  { path: "/help", name: "Help", component: Help, exact: true }
];

export default routes;

/* prettier-ignore */
export const superAdminRoutes = [
  { path: "/group-create", name: "Group Create", component: GroupCreate, exact: true },
];
